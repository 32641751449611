<template>
  <b-container fluid class="p-5">
    <div v-if="getDescription() != null">
      <h3 class="text-info mb-3">الخدمة الفرعية</h3>
      <div>
        {{ getDescription() }}
      </div>
    </div>

    <div>
      <h3 class="text-info mt-5 mb-3">المصارف التي توفر الخدمة</h3>
      <b-row>
        <b-col
          v-for="(option, index) in banks"
          :key="index"
          lg="4"
          md="6"
          v-b-modal.mobile-modal
        >
          <div class="mb-3 bg-grey rounded p-5 text-center" style="height: 94%">
            <img
              :src="option.logo"
              class="rounded-circle"
              width="90"
              height="90"
            />
            <h5 class="text-secondary my-3">{{ option.name }}</h5>
          </div>
        </b-col>
      </b-row>
      <VDownloadMobile></VDownloadMobile>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      serviceList: [
        {
          name: "loans_and_fundings",
          description: "تعرف على الشروط والاليات وطرق التسديد ",
        },
        {
          name: "localizations",
          description:
            "هي عملية تحويل رواتب الموظفين بالقطاع الخاص او العام من رواتب يدوية يتم استلامها من دوائرهم الى ان يتم استلامها من البنوك والمصارف الحكومية والاهلية وذلك من خلال حساب خاص يفتح للموظف في المصرف ويقوم بالسحب منه بواسطة بطاقة الدفع الالكترونية او ان يستلمها نقدا من المصرف.",
        },
        {
          name: "cards",
          description: "تعرف على انواع البطاقات ومتطلبات الحصول عليها",
        },
        {
          name: "deposit_accounts",
          description: "تعرف على أنواع الحسابات المصرفية ومتطلبات فتح الحساب",
        },
        {
          name: "external_fundings",
          description: "الاعتمادات",
        },
        {
          name: "letters_of_guarantees",
          description:
            "هو تعهد خطي  صادر من مصرف ( المصرف الكفيل او المصرف مصدر الكفالة) يتعهد بموجبه بان يدفع لأمر جهة معينة (الجهة المستفيدة) مبلغ ما خلال فترة زمنية محددة في الخطاب (مدة صلاحية الكفالة) ويأتي هذا التعهد بناءا على طلب عميله المكفول ويكون التعهد لتنفيذ عملية الدفع مرتبطا بعدم قيام المكفول بتنفيذ الشرط او الغرض او الموضوع المذكور صراحة في متن خطاب الضمان نفسه.",
        },
        {
          name: "online_services",
          description:
            "وهي ان يستطيع العميل بالقيام باجراء عمليات مصرفية بنفسه دون الحاجة الى مراجعه فروع المصرف حيث يمكن اجراء تحويل للمبالغ بين حسابات الزبون ,الحصول على كشف بالحركات ,معرفة الرصيد ,تحويل من حساب الزبون الى حساب زبون اخر ضمن البنك نفسه ,بلاضافة الى امكانية تقديم الطلبات مثل طلب دفتر صكوك ,طلبات فتح الحسابات وغيرها.",
        },
        {
          name: "western_unions",
          description:
            "خدمة ويسترن يونين لتحويل الاموال هي خدمة يوفرها المصرف المعني بالتعاون مع شركة ويسترن يونين التي تقوم باعمال تحويل الاموال من شخص لاخر عبر العالم .",
        },
        {
          name: "other_services",
          description: null,
        },
      ],
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    banks() {
      return this.$store.state.website.banks.list;
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  methods: {
    get() {
      this.$store.dispatch("website/banks/service", this.$route.params.type);
    },
    getDescription() {
      return this.serviceList.find((x) => x.name == this.$route.params.type)
        .description;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background-color: #edf4fc !important;
}
.col-md-6,
.col-lg-4 {
  transition: 0.3s ease !important;
}
.col-md-6:hover,
.col-lg-4:hover {
  transform: scale(1.1);
  cursor: pointer;
}
</style>
